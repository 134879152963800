import React, { useState } from "react";
import styled from "styled-components";
import points from "./data.json";
import { BsChevronCompactRight } from "react-icons/bs";
const CourseContent = () => {
  console.log(points);
  const [recepients, setRecepients] = useState([]);
  return (
    <Container>
      <h1 className="module">
        HEALTH PROMOTION OFFICER: NQF LEVEL 3 (CREDITS 163) – QCTO ACCREDITED.
        DEVELOPMENT
      </h1>
      <br />
      <h2>QUALITY PARTNER – HWSETA</h2>

      <p>This qualification replaces:</p>
      <ul>
        <li>
          ID 64749 – National Certificate: Community Health Work NQF Level 2
        </li>

        <li>
          ID 64749 – National Certificate: Community Health Work NQF Level 3
        </li>
      </ul>
      <p>
        Duration: This is a one year qualification comprising knowledge
        subjects, practical skills modules and work experience modules.
      </p>

      <br />

      <h1>Access to the qualification</h1>
      <ul>
        <li>
          The learner must be adequately mobile to execute the work within a
          community
        </li>
        <li>
          Adequate visual and hearing capacity to master the skills as described
          in the
        </li>
        <li>Curriculum document</li>
      </ul>

      <br />

      <h1>Purpose</h1>
      <ul>
        <li>The purpose of this qualification is to prepare a learner to:</li>
        <ul>
          <li>
            Carry out basic assessments of communities, households, groups and
            individuals
          </li>
          <li>
            Provide basic health education and referrals for a wide range of
            services
          </li>
          <li>
            Support and assist in navigating the health and social services
          </li>
        </ul>
      </ul>
      <br />
      <div className="dropdown__container">
        <div className="dropdown__1" onClick={() => setRecepients(!recepients)}>
          <div className="row">
            <h1>Recipients of this qualification will be able to:</h1>
            <BsChevronCompactRight />
          </div>
          <ul>
            {points.map((point, index) => (
              <li key={index}>{point.point}</li>
            ))}
          </ul>
        </div>
      </div>
      <br />
      <h1>Entry Requirements</h1>
      <ul>
        <li>Ancillary Health Care NQF Level 1 or</li>
        <li>ABET Level 4 with FLC</li>
        <li>Grade 9, 10, 11 or 12 (No specific subjects are required).</li>
        <li>
          NQF Level 1 or equivalent competences in Mathematical Literacy and
          Communications.
        </li>
      </ul>
      <br />
      <h1>What can one do after obtaining this qualification?</h1>
      <ol>
        <li>Find employment.</li>
        <ul>
          <li>
            Major employer is Department of Health (Clinics, rehabilitation
            centres, testing and counseling centres) through its implementation
            of Primary Health Care Re-engineering under NHI.
          </li>
          <li>
            Other employers include Schools, waste disposal plants, water
            purification plants, local municipalities, Mental Health Centres,
            Old age homes, mines, Health Research centers, Cancer foundation,
            heart foundation, diabetes foundation, social department, department
            of housing and NGOs.
          </li>
        </ul>
        <li>Enroll for higher level qualifications such as:</li>
        <ul>
          <li>
            Further Education and Training Certificate: Community Health Work at
            NFQ Level 4
          </li>
          <li>
            Further Education and Training Certificate: Community Development:
            HIV/AIDS Support,
          </li>
          <li>Higher Certificate in Social Auxiliary Work at NQF Level 5</li>
          <li>
            Mid level course is being developed to bridge to Degree in Health
            Promotion Officer Degree in Health Promotion
          </li>
        </ul>
      </ol>
    </Container>
  );
};

const Container = styled.div`
  ul,
  ol {
    margin-left: 2rem;
  }

  .module {
    font-weight: 300;
  }
  .dropdown__container {
    width: 100%;

    .dropdown__1 {
      cursor: pointer;
      transition: 0.4s ease-in-out;
      padding: 2rem;
      h1 {
        font-size: 1rem;
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;
export default CourseContent;
