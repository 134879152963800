import React from "react";
import styled from "styled-components";
import { FaShower } from "react-icons/fa";
import { MdOutlineKitchen, MdSportsSoccer } from "react-icons/md";
import { IoIosBook } from "react-icons/io";
import { GiBed, GiVacuumCleaner, GiLightningSpanner } from "react-icons/gi";
import { RiFridgeLine } from "react-icons/ri";
import { ImPower } from "react-icons/im";
import { AiOutlineWifi } from "react-icons/ai";
const Content = () => {
  return (
    <ContentContainer>
      <h1>What's on offer?</h1>
      <div className="icon__container">
        <div className="left">
          <div className="field">
            <FaShower size={50} color="red" className="icon" />
            <p>Private bathroom with shower, basin and toilet</p>
          </div>
          <div className="field">
            <MdOutlineKitchen size={50} color="red" className="icon" />
            <p>Access to a kitchenette with fridge</p>
          </div>

          <div className="field">
            <GiBed size={50} color="red" className="icon" />
            <p>Bed and mattress</p>
          </div>
          <div className="field">
            <GiVacuumCleaner size={50} color="red" className="icon" />
            <p>Daily cleaning services</p>
          </div>
        </div>

        <div className="right">
          <div className="field">
            <RiFridgeLine size={50} color="red" className="icon" />
            <p>Built-in cupboard</p>
          </div>
          <div className="field">
            <AiOutlineWifi size={50} color="red" className="icon" />
            <p>Wi-Fi</p>
          </div>
          <div className="field">
            <ImPower size={50} color="red" className="icon" />
            <p>Water & Electricity</p>
          </div>
          <div className="field">
            <GiLightningSpanner size={50} color="red" className="icon" />
            <p>Dedicated maintenance team</p>
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1.5rem;
  .icon__container {
    display: flex;
    width: 100%;
    @media (max-width: 1095px) {
      flex-direction: column;
    }
    .left,
    .right {
      width: 50%;
      @media (max-width: 1095px) {
        width: 100%;
      }
      .field {
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
        text-align: center;

        .icon {
          margin-right: 1rem;
        }
      }
    }
  }
`;
export default Content;
