import React from "react";
import styled from "styled-components";
import banner from "../../images/accommodation/accommodation_5.jpg";
const Banner = () => {
  return (
    <BannerContainer>
      <img src={banner} alt="" className="banner" />
      <div className="overlay">
        <h1>Accommodation</h1>
      </div>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  .banner {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

  .overlay {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
  }
`;
export default Banner;
