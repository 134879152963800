import React from "react";
import Navbar from "../../components/Navbar";
import Title from "../../components/Title";
import styled from "styled-components";
import Course1 from "../../components/courses__page/Course1";
import Course2 from "../../components/courses__page/Course2";
import Footer from "../../components/Footer";
import course__logo from "../../images/courses__banner.jpg";
import CourseCard1 from "../../components/courses__page/CourseCard1";
import health__promotion__officer from "../../images/health__promotion__officer.jpg";
import youth__care__worker from "../../images/youth__care__worker.jpg";
import ecd__card__image from "../../images/ecd__card__image.jpg";
import healthcare__quality__improvement__plan from "../../images/healthcare__quality__improvement__plan.jpg";
import hospital__management__and__leadership from "../../images/hospital__management__and__leadership.jpg";
import hospital__management from "../../images/hospital__management.jpg";
const Courses = () => {
  return (
    <>
      <CourseContainer>
        <Navbar />
        <div className="title">
          <img src={course__logo} alt="" />
          <div className="banner__title">
            <h1>Courses</h1>
          </div>
        </div>

        <div className="course__card__container">
          <div className="course__card__content__container">
            <CourseCard1
              image={health__promotion__officer}
              card__heading="Health Promotion Officer"
              card__price={"R5 000.00"}
              duration={"12 Months"}
              description="The purpose of this qualification is to prepare a learner to:
              Carry out basic assessments of communities, households, groups and individuals
              Provide basic health education and referrals for a wide range of services
              Support and assist in navigating the health and social services"
              link={"/courses/health_promotion_officer"}
              provinces={"KZN"}
            />
            <CourseCard1
              image={youth__care__worker}
              card__heading="Child and Youth Care Worker"
              card__price={"R12 000.00"}
              duration={"12 Months"}
              description="For those who want to enter the field of Child & Youth Care Work (C&YCW) as a potential career. It builds on practical experience and community-based learning, and equips people for their role as an auxiliary child and youth care worker."
              link={"/courses/child_and_youthcare_worker"}
              provinces={"KZN"}
            />
            <CourseCard1
              image={ecd__card__image}
              card__heading="Early Childhood Development"
              card__price={"R12 000.00"}
              duration={"12 Months"}
              description="To provide basic knowledge and skills in early childhood development (birth to five years) in order to facilitate all-round development of young children. "
              link={"/courses/early_childhood_development"}
              provinces={"KZN"}
            />
            <CourseCard1
              image={healthcare__quality__improvement__plan}
              card__heading=" Healthcare Quality Improvement Plan"
              card__price={"R12 000.00"}
              duration={"12 Months"}
              description="Healthcare Quality Improvement Plan."
              link={"/courses"}
              provinces={"KZN"}
            />
            <CourseCard1
              image={hospital__management__and__leadership}
              card__heading="Hospital Management"
              card__price={"R12 000.00"}
              duration={"12 Months"}
              description="Hospital Management."
              link={"/courses"}
              provinces={"Mthatha, Mpumalanga, Northern Cape and KZN"}
            />
            <CourseCard1
              image={hospital__management}
              card__heading="Hospital Management and Leadership"
              card__price={"R12 000.00"}
              duration={"12 Months"}
              description="Hospital Management and Leadership."
              link={"/courses"}
              provinces={"Mthatha, Mpumalanga, Northern Cape and KZN"}
            />
          </div>
        </div>
      </CourseContainer>
      <Footer />
    </>
  );
};

const CourseContainer = styled.div`
  width: 100%;

  .title {
    width: 100%;
    height: 400px;
    position: relative;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .banner__title {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      h1 {
        font-weight: 300;
        font-size: 4rem;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .course__card__container {
    width: 100%;

    .course__card__content__container {
      max-width: 1600px;
      margin: auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      padding: 1rem;

      @media (max-width: 870px) {
        flex-wrap: wrap;
      }
    }
  }
`;
export default Courses;
