import React from "react";
import styled from "styled-components";
import accommodation1 from "../../images/accommodation/accommodation_1.jpg";
import accommodation2 from "../../images/accommodation/accommodation_2.jpg";
import accommodation3 from "../../images/accommodation/accommodation_3.jpg";
import accommodation4 from "../../images/accommodation/accommodation_4.jpg";
const AccommodationContent = () => {
  return (
    <ContentContainer>
      <h1 style={{ fontWeight: "300" }}>
        We have 5 properties in Hibberdene & 1 in Shelly Beach{" "}
      </h1>
      <br />
      <p>
        We are nestled in the Kwa-zulu Natal South Coast & strive to provide our
        students with the comforts of a home environment. Our accommodation is
        spread over Hibberdene & Shelly Beach & we cater to all our students.
        All our properties boast beach access which allows our students a
        tranquil & serene atmosphere in which to relax after a long day of
        lectures.
      </p>

      <div className="grid__container">
        <div className="image__container">
          <img src={accommodation1} alt="" />
        </div>
        <div className="image__container">
          <img src={accommodation2} alt="" />
        </div>
        <div className="image__container">
          <img src={accommodation3} alt="" />
        </div>
        <div className="image__container">
          <img src={accommodation4} alt="" />
        </div>
      </div>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1rem;

  .grid__container {
    display: grid;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .image__container {
      width: auto;
      height: 500px;
      margin: 1rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
`;
export default AccommodationContent;
