import React from "react";
import {
  AiOutlineTeam,
  AiOutlineAreaChart,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { IoMdMan } from "react-icons/io";
import styled from "styled-components";
const Values = () => {
  return (
    <ValuesContainer>
      <div className="title"></div>
      <div className="card">
        <AiOutlineTeam className="icon" />
        <p className="card__title">Respect</p>
      </div>
      <div className="card">
        <IoMdMan className="icon" />
        <p className="card__title">Integrity</p>
      </div>
      <div className="card">
        <AiOutlineAreaChart className="icon" />
        <p className="card__title">Teaming</p>
      </div>
      <div className="card">
        <AiOutlineCheckCircle className="icon" />
        <p className="card__title">Excellence</p>
      </div>
    </ValuesContainer>
  );
};

const ValuesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 3rem 0;

  .card {
    width: 200px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    margin: 2rem;

    &:hover {
      background-color: #f0f0f0;
    }
    .icon {
      font-size: 2rem;
      color: red;
    }

    .card__title {
      font-weight: 300;
      font-size: 1.4rem;
    }
  }

  @media (max-width: 640px) {
    margin: 0;
    .card {
      width: 100%;
      margin: 1rem 0;
    }
  }
`;

export default Values;
