import React from "react";
import styled from "styled-components";
const CourseContent = () => {
  return (
    <Container>
      <br />
      <h2>Project Plan</h2>

      <table>
        <thead>
          <th>Module</th>
          <th>Competencies</th>
        </thead>

        <tbody>
          <tr>
            <td>
              The National Quality Improvement Plan (NQIP) Regulatory
              environment; OHSC; NQIP rollout, Role of Boards and CEos
            </td>
            <td>
              Regulatory environment; OHSC; NQIP rollout, Role of Boards and
              CEos
            </td>
          </tr>
          <tr>
            <td>Principles of Corporate Governance</td>
            <td>Kings report. NHS Board approach; ARQH Board approach(USA)</td>
          </tr>
          <tr>
            <td>Organizing for Quality and patient safety</td>
            <td>
              The HQS 360 framework: Charter; Organization, Competencies;
              Linkages
            </td>
          </tr>
          <tr>
            <td>Introduction to Clinical Quality Improvement</td>
            <td>
              Clinical quality improvement theory; classes of outcomes; process
              management; management tools and modeling techniques for
              Improvement of clinical processes and decision‐making.
            </td>
          </tr>
          <tr>
            <td>Quantitative Methods, Measurement, and Tools for Quality</td>
            <td>
              Improvement – Statistical process control techniques applied to
              Clinical and patient service processes, including Lean and Six
              Sigma methodologies; data system design concepts applied to
              clinical and financial data for managing health care business
              processes
            </td>
          </tr>
          <tr>
            <td>Fundamentals of Patient Safety</td>
            <td>
              Nature and science of patient error; strategies for design of
              safety‐ critical systems; hazard analysis and risk assessment in
              health care organizations; design elements of safety program and
              high reliability systems
            </td>
          </tr>
          <tr>
            <td>Leadership of High Reliability Healthcare Organizations</td>
            <td>
              Applying concepts of high reliability organizations to create an
              Organizational culture that supports strong, functional, and safe
              patient care environments resulting in quality clinical care and
              patient satisfaction. Principles of organization for HROs –
              Structure, systems
            </td>
          </tr>
          <tr>
            <td>Managing the Patient Experience</td>
            <td>
              Rationale for patient engagement. Lessons from other industries.
              Patient engagement as Strategy. Processes and tools that support
              patient engagement.
            </td>
          </tr>
        </tbody>
      </table>

      <h1>1. Target Group</h1>
      <p>
        All candidates will be in current employment by the Different Provincial
        Department of SD. They will include:
      </p>
      <ul>
        <li>Hospital Boards and CEOs in Quality and Safety</li>
      </ul>

      <h1>2. Opportunities Upon Completion</h1>
      <p>
        Students who successfully complete the course will receive a certificate
        of completion and any approved relevant accreditations.
      </p>

      <h1>3. CAPACITY TO MANAGE THE TRAINING PROGRAMME</h1>
      <p>
        Project Governance <br />
        The dedicated project management team will inter alia ensure that:{" "}
        <br />
        All accreditation and registration requirements are met with HWSETA/ETQA
        All assessor and moderators are registered ETQA and Monitoring and
        Evaluation functions are fulfilled All participant information is
        recorded by all relevant stakeholders like HWSETA and DOH All relevant
        training, assessing and moderating resources are available There is
        constant communication with DOH
      </p>
      <h1>4. PREVIOUS EXPERIENCE IN MANAGING SIMILAR PROJECT</h1>
      <ul>
        <li>
          The African Institute of Health Leadership (AIHL) is a non-profit
          organization registered in South Africa (NP 217-998) and accredited
          with HWSETA. The Mission of AIHL is to:
        </li>
        <li>
          Develop a diverse group of creative, innovative and entrepreneurial
          healthcare policymakers, leaders and workers
        </li>
        <li>Foster and support innovative leadership strategies</li>
        <li>
          Leverage technology to improve healthcare quality, access and cost
        </li>
        <li>
          Influence policy and practices aimed at optimizing health and
          healthcare especially for underserved populations.
        </li>
      </ul>
    </Container>
  );
};

const Container = styled.div`
  h1 {
    font-size: 2rem;
    font-weight: 400;
    margin-top: 2rem;
  }
  ul,
  ol {
    margin-left: 2rem;
  }

  .module {
    font-weight: 300;
  }

  table {
    width: 100%;
    height: fit-content;
    border: 1px solid black;

    thead {
      text-align: left;
      th {
        padding: 1rem;
      }
    }

    tbody {
      tr {
        td {
          padding: 1rem;
        }
      }
    }
  }
`;
export default CourseContent;
