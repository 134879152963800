import React from "react";
import styled from "styled-components";
const Course1 = () => {
  return (
    <Course1Container>
      <div className="course__1__content__container">
        <h1>
          HEALTH PROMOTION OFFICER: NQF LEVEL 3 (CREDITS 163) – QCTO ACCREDITED.
          DEVELOPMENT
        </h1>

        <h2>QUALITY PARTNER – HWSETA</h2>
        <br />
        <p>This qualification replaces:</p>
        <ul>
          <li>
            ID 64749 – National Certificate: Community Health Work NQF Level 2
          </li>
          <li>
            ID 64749 – National Certificate: Community Health Work NQF Level 3
          </li>
        </ul>
        <p>
          Duration: This is a one year qualification comprising knowledge
          subjects, practical skills modules and work experience modules.
        </p>
        <br />

        <h2>Access to the qualification</h2>

        <ul>
          <li>
            The learner must be adequately mobile to execute the work within a
            community
          </li>
          <li>
            Adequate visual and hearing capacity to master the skills as
            described in the
          </li>
          <li>Curriculum document</li>
        </ul>

        <br />

        <h2>Purpose</h2>

        <ul>
          <li>The purpose of this qualification is to prepare a learner to:</li>
          <li>
            Carry out basic assessments of communities, households, groups and
            individuals
          </li>
          <li>
            Provide basic health education and referrals for a wide range of
            services
          </li>
          <li>
            Support and assist in navigating the health and social services
          </li>
        </ul>

        <br />

        <h2>Recipients of this qualification will be able to:</h2>
        <ul>
          <li>
            Treat simple diseases, injuries, wounds and refer to the clinic
          </li>
          <li> Provide basic nursing care</li>
          <li>Provide palliative care</li>
          <li> Take temperature, Bp, Pulse and respiration</li>
          <li>
            Check outbreak of transmitted diseases, teach and refer for
            treatment
          </li>
          <li>
            Check Sexual Transmitted Infections, teach and refer for treatment
          </li>
          <li>Check children and refer for immunization</li>
          <li>
            Check pregnant mothers, mothers who have given birth and their
            babies and refer for treatment
          </li>
          <li>HIV and AIDS testing and counseling</li>
          <li>
            Check drug and substance abusers and refer them to rehabilitation
            centers
          </li>
          <li>Teach people about dangers of drug and substance abuse</li>
          <li>
            Teach people about cleanliness of in-house, environment and water
          </li>
          <li>Teach about women and men’s health</li>
          <li>Teach about child’s health</li>
          <li>Teach about health life styles</li>
          <li>
            Conduct household assessments and identify those at risk of health
            related issues including impairments in health
          </li>
          <li>
            Provide information, education and support for healthy behaviors and
            appropriate home care
          </li>
          <li>Provide psychosocial support</li>
          <li>Identify and manage minor health problems</li>
        </ul>

        <br />

        <h2>Entry Requirements</h2>
        <ul>
          <li>Ancillary Health Care NQF Level 1 or</li>
          <li>ABET Level 4 with FLC</li>
          <li>Grade 9, 10, 11 or 12 (No specific subjects are required).</li>
          <li>
            NQF Level 1 or equivalent competences in Mathematical Literacy and
            Communications.
          </li>
        </ul>

        <br />

        <h2>What can one do after obtaining this qualification?</h2>
        <ol>
          <li>Find employment.</li>

          <ul>
            <li>
              Major employer is Department of Health (Clinics, rehabilitation
              centres, testing and counseling centres) through its
              implementation of Primary Health Care Re-engineering under NHI.
            </li>
            <li>
              Other employers include Schools, waste disposal plants, water
              purification plants, local municipalities, Mental Health Centres,
              Old age homes, mines, Health Research centers, Cancer foundation,
              heart foundation, diabetes foundation, social department,
              department of housing and NGOs.
            </li>
          </ul>

          <li>Enroll for higher level qualifications such as:</li>
          <ul>
            <li>
              Further Education and Training Certificate: Community Health Work
              at NFQ Level 4
            </li>
            <li>
              Further Education and Training Certificate: Community Development:
              HIV/AIDS Support,
            </li>
            <li>Higher Certificate in Social Auxiliary Work at NQF Level 5</li>
            <li>
              Mid level course is being developed to bridge to Degree in Health
              Promotion Officer Degree in Health Promotion
            </li>
          </ul>
        </ol>
      </div>
    </Course1Container>
  );
};
const Course1Container = styled.div`
  width: 100%;
  padding: 1rem;

  ul,
  ol {
    margin-left: 2rem;
  }
  .course__1__content__container {
    max-width: 1600px;
    margin: auto;
    -webkit-box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
    padding: 2rem;
    border-radius: 12px;

    h1 {
      font-size: 2rem;
      font-weight: 500;
      color: red;
    }
  }
`;
export default Course1;
