import React from "react";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const Map = (coordinates) => {
  console.log(coordinates);

  return (
    <MapContentContainer>
      <div className="map__container">
        <div className="title">
          <h1>Location Map</h1>
        </div>
        <MapContainer
          center={[coordinates.coordinates.lat, coordinates.coordinates.lng]}
          zoom={13}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={[
              coordinates.coordinates.lat,
              coordinates.coordinates.lng,
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </MapContentContainer>
  );
};

const MapContentContainer = styled.div`
  .map__container {
    .title {
      background-color: red;
      color: white;
      padding: 1rem;
      h1 {
        font-weight: 300;
      }
    }
  }
  .leaflet-container {
    width: 100%;
    height: 500px;
  }
  .map__container {
    margin-top: 2rem;
  }
`;
export default Map;
