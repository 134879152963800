import React from "react";
import AccommodationContent from "../../components/accommodation__page/AccommodationContent";
import Banner from "../../components/accommodation__page/Banner";
import Content from "../../components/accommodation__page/Content";
import Navbar from "../../components/Navbar";
import styled from "styled-components";
import Map from "../../components/courses__page/Map";
import Footer from "../../components/Footer";
const Accommodation = () => {
  const coordinates = {
    lat: -30.5754213,
    lng: 30.57521,
  };
  return (
    <>
      <Navbar />
      <Banner />
      <AccommodationContainer>
        <div className="main__content">
          <div className="left">
            <AccommodationContent />
          </div>

          <div className="right">
            <Map coordinates={coordinates} />
          </div>
        </div>

        <Content />
      </AccommodationContainer>

      <Footer />
    </>
  );
};

const AccommodationContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  .main__content {
    display: flex;

    .left {
      width: 70%;
    }

    .right {
      width: 30%;
    }
  }

  @media (max-width: 1000px) {
    .main__content {
      flex-direction: column;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
      }
    }
  }
`;
export default Accommodation;
