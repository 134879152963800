import React from "react";
import styled from "styled-components";
import route from "../../images/transportation/route.PNG";
const TransportContent = () => {
  return (
    <TransportContentContainer>
      <h1>Route</h1>
      <p>
        We provide transportation to & from all our venues for our students. Our
        drivers are well trained & highly skilled & are equipped to handle any
        situation on the road. We have a few vehicles that we use to transport
        our students :
      </p>
      <ul>
        <li>1 x 23 Seater Bus </li>
        <li>3 x 10 Seater Vans</li>
      </ul>
      <div className="route__container">
        <img src={route} alt="" className="route" />
      </div>
    </TransportContentContainer>
  );
};

const TransportContentContainer = styled.div`
  .route__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .route {
      width: 400px;
      height: 400px;
      object-fit: contain;
    }
  }

  li {
    margin-left: 2rem;
  }
`;
export default TransportContent;
