import React from "react";
import BannerComponent from "../../components/BannerComponent";
import Navbar from "../../components/Navbar";
import banner from "../../images/catering/catering_4.jpg";
import styled from "styled-components";
import CateringContent from "../../components/catering__page/CateringContent";
import Footer from "../../components/Footer";
const Catering = () => {
  return (
    <>
      <Navbar />
      <BannerComponent banner={banner} heading="Catering" />
      <CateringContainer>
        <CateringContent />
      </CateringContainer>
      <Footer />
    </>
  );
};

const CateringContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1.5rem;
`;

export default Catering;
