import React from "react";
import BannerComponent from "../../components/BannerComponent";
import Navbar from "../../components/Navbar";
import banner from "../../images/classroom/classroom_3.jpg";
import styled from "styled-components";
import Footer from "../../components/Footer";
import CourseCard1 from "../../components/courses__page/CourseCard1";
import ecd__card__image from "../../images/ecd__card__image.jpg";
import health__promotion__officer from "../../images/health__promotion__officer.jpg";
import youth__care__worker from "../../images/youth__care__worker.jpg";
const SkillsDevelopment = () => {
  return (
    <>
      <Navbar />
      <BannerComponent banner={banner} heading="Skills Development" />
      <TransportContainer>
        <div className="container">
          <h1>Skills Development</h1>
          <p>
            We are an NPO which is funded by HWSETA to train & develop skills in
            students with programmes that are non-credit bearing as well as
            Credit bearing & students are thereafter issued with certificates
            once successfully completed.
          </p>

          <h1>Early Childhood Development Project Breakdown</h1>
          <p>
            ELET runs ECD Learnerships that are funded by HWSETA. Each
            learnership has its different requirements and demographics. The
            admin aspect of the course is standard throughout the 12 months of
            the programme . This presentation demonstrates the admin and
            reporting from inception of the Learnership to the end.
          </p>

          <h1>Project Inception</h1>
          <p className="pointText">
            <span style={{ fontWeight: "bold" }}>MOA</span> ELET or the Employer
            would receive an MOA from the funder outlining the Project
            requirements.
          </p>
          <p className="pointText">
            <span style={{ fontWeight: "bold" }}>PROJECT PARTNERS</span> in some
            instances ELET`s role is only the training provider; therefore, we
            need SLA`s drawn up between the Employer and Training Provider.
          </p>
          <p className="pointText">
            <span style={{ fontWeight: "bold" }}>SAQA LEVEL</span> ELET offers
            level 4 (SAQA ID 58761) & Level 5 (SAQA 64650)
          </p>
          <p className="pointText">
            <span style={{ fontWeight: "bold" }}>RECRUITMENT</span> Learners are
            then recruited by the relevant parties and are screened accordingly
            to the entry requirements for each level.
          </p>
          <p className="pointText">
            <span style={{ fontWeight: "bold" }}>LEARNERS AGREEMENTS</span> Each
            cohort of learners need to complete Agreement forms and sign
            contracts. This is required by the funder. These agreements need to
            be scanned and electronic copies needs to be sent to the funder.
          </p>
          <p className="pointText">
            <span style={{ fontWeight: "bold" }}>SPREADSHEET</span> learners'
            details from the agreement are then captured on a spreadsheet that
            is submitted to the funder.
          </p>
          <p className="pointText">
            Facilitator recruitment and contract signing. Site visit and learner
            travel is arranged.
          </p>
          <p className="pointText">
            <span style={{ fontWeight: "bold" }}>Indicium</span> learners are
            captured on the indicium system, this is the ETDPSETA capturing
            portal. Learners' assessment results are loaded onto this portal
            after the required modules are completed, assessed and moderated.
          </p>
          <p className="pointText">
            <span style={{ fontWeight: "bold" }}>SOR & Certificates</span> -
            Learners Statement of results are downloaded from the Indicium
            portal. We would need assistance with the electronic storing and
            accessibility if this.
          </p>

          <div
            className="course__container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <CourseCard1
              image={ecd__card__image}
              card__heading="Early Childhood Development"
              card__price={"R12 000.00"}
              duration={"12 Months"}
              description="To provide basic knowledge and skills in early childhood development (birth to five years) in order to facilitate all-round development of young children. "
              link={"/courses/early_childhood_development"}
              provinces={"KZN"}
            />
            <CourseCard1
              image={health__promotion__officer}
              card__heading="Health Promotion Officer"
              card__price={"R5 000.00"}
              duration={"12 Months"}
              description="The purpose of this qualification is to prepare a learner to:
              Carry out basic assessments of communities, households, groups and individuals
              Provide basic health education and referrals for a wide range of services
              Support and assist in navigating the health and social services"
              link={"/courses/health_promotion_officer"}
              provinces={"KZN"}
            />
            <CourseCard1
              image={youth__care__worker}
              card__heading="Child and Youth Care Worker"
              card__price={"R12 000.00"}
              duration={"12 Months"}
              description="For those who want to enter the field of Child & Youth Care Work (C&YCW) as a potential career. It builds on practical experience and community-based learning, and equips people for their role as an auxiliary child and youth care worker."
              link={"/courses/child_and_youthcare_worker"}
              provinces={"KZN"}
            />
          </div>
        </div>
      </TransportContainer>
      <Footer />
    </>
  );
};

const TransportContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1.5rem;

  h1 {
    color: red;
    margin-bottom: 1rem;
  }

  .pointText {
    margin: 15px 0;
  }

  p {
    margin: 1rem 0;
  }
  .container {
    max-width: 1600px;
    margin: auto;
    padding: 1.5rem;
  }
`;

export default SkillsDevelopment;
