import React from "react";
import styled from "styled-components";
const SecurityContent = () => {
  return (
    <SecurityContentContainer>
      <h1>Onsite security provided</h1>
      <br />
      <p>
        We have a highly skilled security team who go above & beyond when it
        comes to the safety of our students. We have implemented a 6pm curfew
        for the safety of our students, our guards diligently enforce. We thrive
        on providing a safe secure environment for all our students.
      </p>
      <br />
      <h3>Resident Safety</h3>
      <p>
        Security is provided which assists all students, and visitors in
        creating a safe environment for the resident community. Security
        officers patrol the grounds of the residence and are trained to ensure
        the safety of the residence community and to assist in emergencies. ​
      </p>
      <br />
      <p>
        Students and staff are encouraged to take ​safety precuations by
        following these tips:
      </p>

      <ul>
        <li>Never walk alone, especially at night.</li>
        <li>
          Walk in groups, use the shuttle service or request security to escort
          you.
        </li>
        <li>Keep your phone and valuables out of sight.</li>
        <li>
          Carry a whistle and pepper spray at all times to be able to draw
          attention if needed.
        </li>
        <li>Choose the safest route, even if it is not the shortest.</li>
        <li>
          Avoid isolated areas, especially when you exercise early in the
          morning or late in the afternoon.
        </li>
        <li>
          Be conscious of your environment, as criminals prefer to target
          someone not aware of his/her direct surroundings.
        </li>
        <li>Keep a safe distance if a motorist asks for directions.</li>
        <li>
          If you suspect that someone might be following you, change direction.
        </li>
        <li>Be a caring Matie: accompany a friend.</li>
        <li>
          Property on a car seat is tempting to a thief. Lock it in the boot.
        </li>
      </ul>
      <br />
    </SecurityContentContainer>
  );
};

const SecurityContentContainer = styled.div`
  p {
    font-size: 20px;
  }

  li {
    font-size: 20px;
    margin-left: 2rem;
  }
`;
export default SecurityContent;
