import React from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import Banner from "../../components/landing__page/Banner";
import Banner2 from "../../components/landing__page/Banner2";
import Banner3 from "../../components/landing__page/Banner3";
import OurPartners from "../../components/landing__page/OurPartners";
import Values from "../../components/landing__page/Values";
import Navbar from "../../components/Navbar";
const LandingPage = () => {
  return (
    <LandingPageContainer>
      <div className="landing__page__content">
        <Navbar />
        <Banner />
        <Banner2 />
        <Banner3 />
        <OurPartners />
      </div>

      <Footer />
    </LandingPageContainer>
  );
};

const LandingPageContainer = styled.div`
  width: 100%;
`;
export default LandingPage;
