import React from "react";
import styled from "styled-components";
import banner from "../../images/banner.jpg";
import Title from "../Title";
import Values from "./Values";
import logo from "../../images/icon.png";
const Banner = () => {
  return (
    <BannerContainer>
      <div className="banner__content__container">
        <img src={banner} alt="" className="banner" />
        <div className="our__partners">
          <img src={logo} alt="" className="partner" />
          <div className="content">
            <h1 style={{ fontWeight: "300" }}>
              Transforming Health and Healthcare through Thought Leadership and
              Capacity Development
            </h1>
          </div>
        </div>
      </div>

      <div className="banner__content">
        <div className="banner__content__title">
          <Title title="Our Values" />
        </div>
        <Values />
        <Title title={"About AIHL"} />

        <p className="content">
          The African Institute for Health Leadership aims to provide thought
          leadership and provide skills development through a number of our
          initiatives and programming. The Institute is based in Gauteng and on
          the Natal South Coast with locations in Hibberdene & Shelly Beach. Our
          capacity development initiatives target executives and senior
          management from health, social development and e government. The
          institute also focuses on empowering individuals from disadvantaged
          communities by offering turn key educational and training services
          including transportations, accommodation and programmings. At the
          Institute we offer services to cater to students to ensure that they
          are comfortable whilst completing their studies. We offer
          Accommodation, In-House Catering as well as Transportation to and from
          the students homes & the Institute.
        </p>
      </div>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  width: 100%;
  height: fit-content;

  .banner__content__container {
    width: 100%;
    background-color: #f2f2f2;
    position: relative;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .banner {
      width: 100%;
      height: 500px;
      object-fit: cover;
      object-position: center;
    }

    .our__partners {
      z-index: 10000;
      position: absolute;
      color: white;
      display: flex;
      align-items: center;

      .content {
        width: 600px;
        @media (max-width: 870px) {
          width: 400px;
        }
        @media (max-width: 660px) {
          width: 300px;
        }
        @media (max-width: 565px) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .partner {
        width: 300px;
        height: 200px;
        object-fit: contain;

        @media (max-width: 1730px) {
          width: 250px;
          height: 180px;
        }
      }
      @media (max-width: 565px) {
        flex-direction: column;
      }
    }
  }
  .banner__content {
    max-width: 1600px;
    margin: auto;
    padding: 3rem;

    .banner__content__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content {
      margin-bottom: 15px;
    }
  }
`;
export default Banner;
