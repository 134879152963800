import React from "react";
import styled from "styled-components";

const OurPartners = () => {
  return (
    <BannerContainer>
      <div
        className=""
        style={{ width: "fit-content", borderBottom: "3px solid red" }}
      >
        <h1 style={{ color: "black", marginTop: "3rem" }}>Our Partners</h1>
      </div>
      <div className="partner__container">
        <div className="card">
          <h1 className="card__title">HWSETA</h1>
          <img src={require("../../images/partner_1.png")} alt="" />
        </div>
        <div className="card">
          <h1 className="card__title">ELET</h1>
          <img src={require("../../images/elet.jpg")} alt="" />
        </div>
        <div className="card">
          <h1 className="card__title">Limpopo</h1>
          <img
            src={require("../../images/Provinces/Limpopo/limpopo_health.png")}
            alt=""
          />
          <img
            src={require("../../images/Provinces/Limpopo/limpopo_social.png")}
            alt=""
          />
        </div>
        <div className="card">
          <h1 className="card__title">North West</h1>
          <img
            src={require("../../images/Provinces/North_West/nw_health.png")}
            alt=""
          />
          <img
            src={require("../../images/Provinces/North_West/nw_social.png")}
            alt=""
          />
        </div>
        <div className="card">
          <h1 className="card__title">Gauteng</h1>
          <img
            src={require("../../images/Provinces/Gauteng/g_health.png")}
            alt=""
          />
          <img
            src={require("../../images/Provinces/Gauteng/g_social.png")}
            alt=""
          />
        </div>
        <div className="card">
          <h1 className="card__title">Mpumalanga</h1>
          <img
            src={require("../../images/Provinces/Mpumalanga/m_health.png")}
            alt=""
          />
          <img
            src={require("../../images/Provinces/Mpumalanga/m_social.png")}
            alt=""
          />
        </div>
        <div className="card">
          <h1 className="card__title">Northern Cape</h1>
          <img
            src={require("../../images/Provinces/Northern_Cape/nc_health.png")}
            alt=""
          />
          <img
            src={require("../../images/Provinces/Northern_Cape/nc_social.png")}
            alt=""
          />
        </div>
        <div className="card">
          <h1 className="card__title">Free State</h1>
          <img
            src={require("../../images/Provinces/Free_State/fs_health.png")}
            alt=""
          />
          <img
            src={require("../../images/Provinces/Free_State/fs_social.png")}
            alt=""
          />
        </div>
        <div className="card">
          <h1 className="card__title">KwaZulu - Natal</h1>
          <img
            src={require("../../images/Provinces/KZN/kzn_health.png")}
            alt=""
          />
          <img
            src={require("../../images/Provinces/KZN/kzn_social.png")}
            alt=""
          />
        </div>
        <div className="card">
          <h1 className="card__title">Eastern Cape</h1>
          <img
            src={require("../../images/Provinces/Eatern_Cape/ec_health.png")}
            alt=""
          />
          <img
            src={require("../../images/Provinces/Eatern_Cape/ec_social.png")}
            alt=""
          />
        </div>
      </div>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  max-width: 1600px;
  height: fit-content;
  margin: auto;

  .partner__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .card {
      width: 400px;
      height: 400px;
      display: flex;
      margin: 4rem;
      justify-content: center;
      flex-direction: column;
      -webkit-box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
      box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
      border-radius: 15px;
      padding: 3rem;
      .card__title {
        font-weight: 300;
      }

      img {
        width: 70%;
        height: 200px;
        object-fit: contain;
      }
    }
  }
`;
export default OurPartners;
