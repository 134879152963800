import React from "react";
import BannerComponent from "../../components/BannerComponent";
import Navbar from "../../components/Navbar";
import banner from "../../images/classroom/classroom_3.jpg";
import styled from "styled-components";
import Footer from "../../components/Footer";
import CourseCard1 from "../../components/courses__page/CourseCard1";
import pic1 from "../../images/Courses/Health Management and Leadership – CPD Workshop/pic1.jpg";
import pic2 from "../../images/Courses/Executive Training/hm.jpg";
import pic3 from "../../images/Courses/Executive Training/nc.jpg";
const ExecutiveTraining = () => {
  return (
    <>
      <Navbar />
      <BannerComponent banner={banner} heading="Executive Training" />
      <TransportContainer>
        <div className="container">
          <h1>A List of Courses that cater for Executives</h1>
        </div>

        <p style={{ marginBottom: "4rem" }}>
          AIHL core business is to empower and development good work ethics. We
          currently running trainings for Hospital management, CEO and board
          members because we believe in good leadership. Below are the
          programmes that we have trained the Department of Health for all the
          Provinces: 1. Healthcare Quality Improvement Plan a. Organizing for
          Healthcare Quality and Patient Safety(HQS) b. High Reliability
          Healthcare organizations c. Qualitative Methodology in HQs d.
          Quantitative Methodology in HQs e. Managing the Patient and Staff
          Experience in Healthcare f. HQS Practicum 2. Hospital Board and
          Hospital CEO Role in Healthcare Quality and Patient Safety a. A one
          day training which is targeted at hospital managers and CEO's for all
          Provinces 3. Health Management and Leadership a. A one day training
          which is targeted at hospital managers, CEO's and board members
        </p>
        <div className="course__container">
          <CourseCard1
            card__heading={"Health Management and Leadership – CPD Workshop"}
            description="For Health Management and Leadership"
            image={pic1}
            card__price={"R 4200.00"}
            duration={"6 Months"}
            link={"/courses/health_management_and_leadership_cpd_workshop"}
          />
          <CourseCard1
            card__heading={
              "Hospital Management – Hospital Board and Hospital CEO Role in Healthcare Quality and Patient Safety"
            }
            description="For Hospital Management – Hospital Board and Hospital CEO Role in Healthcare Quality and Patient Safety"
            image={pic2}
            card__price={"R 4200.00"}
            duration={"6 Months"}
            link={
              "/courses/hospital__management__hospital__board__and__hospital__ceo__role__in__healthcare__quality__and__patient__safety"
            }
          />
          <CourseCard1
            card__heading={"Non-Credit Bearing Skills Programme"}
            description="For Non-Credit Bearing Skills Programme"
            image={pic3}
            card__price={"R 4200.00"}
            duration={"6 Months"}
            link={"/courses/non__credit__bearing__skills__programme"}
          />
        </div>
      </TransportContainer>
      <Footer />
    </>
  );
};

const TransportContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1.5rem;

  h1 {
    color: red;
    margin-bottom: 1rem;
  }

  .container {
    max-width: 1600px;
    margin: auto;
    padding: 1.5rem;
  }

  .course__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export default ExecutiveTraining;
