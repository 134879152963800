import React from "react";
import styled from "styled-components";
import img_1 from "../../images/catering/catering_1.jpg";
import img_2 from "../../images/catering/catering_2.jpg";
import img_3 from "../../images/catering/catering_3.jpg";
const CateringContent = () => {
  return (
    <CateringContentContainer>
      <p>
        We provide students with nutritious healthy meals provided by our in
        house caterers who source only the best ingredients, if not from our
        garden, then from approved suppliers. Quality is of utmost importance to
        us & we deliver only the highest quality meals.
      </p>
      <div className="field">
        <img src={img_1} alt="" className="img" />
        <div className="content">
          <h1 className="catering__field__heading">What we offer</h1>
          <p className="catering__field__description">
            3 meals a day, through our in-house catering with two teas and
            snacks.
          </p>
        </div>
      </div>
      <div className="field">
        <img src={img_2} alt="" className="img" />
        <div className="content">
          <h1 className="catering__field__heading">Dining</h1>
          <p className="catering__field__description">
            We have a dedicated area where students can gather to have their
            meals or catch up with fellow colleagues.
          </p>
        </div>
      </div>
      <div className="field">
        <img src={img_3} alt="" className="img" />
        <div className="content">
          <h1 className="catering__field__heading">
            Service Personalized To You
          </h1>

          <p className="catering__field__description">
            For us, service is more than just food. Service is about creating a
            memorable experience, sourcing quality ingredients, and providing a
            personalized touch that meets your unique needs no matter the
            occasion.
          </p>
        </div>
      </div>
    </CateringContentContainer>
  );
};

const CateringContentContainer = styled.div`
  width: 100%;

  .field {
    width: 100%;
    display: flex;
    border-bottom: 1px solid lightgrey;
    margin: 10px 0;
    padding: 1rem;
    .img {
      width: 500px;
      height: 400px;
      object-fit: cover;
      border-radius: 20px;
    }
    .content {
      padding: 1.5rem;
    }
  }

  @media (max-width: 850px) {
    .field {
      flex-direction: column;

      .img {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
      .content {
        padding: 1.5rem;
      }
    }
  }
`;
export default CateringContent;
