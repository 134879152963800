import React from "react";
import styled from "styled-components";
import banner2 from "../../images/banner3.jpg";
import Title from "../Title";
import Banner3Accordion from "./Banner3Accordion";
const Banner3 = () => {
  return (
    <BannerContainer>
      <div className="banner2__container">
        <img src={banner2} alt="" />
        <div className="content">
          <Title title={"Strategic Imperatives"} />
          <ul style={{ marginLeft: "5rem" }}>
            <li style={{ margin: "1rem 0" }}>
              Executive Training
              <ol style={{ marginLeft: "1rem" }}>
                <li>Training for quality and patient safety</li>
                <li>Executive Leadership Development Programs</li>
                <li>Training for NHI</li>
              </ol>
            </li>
            <li style={{ margin: "1rem 0" }}>
              Managemenet and Strategy Consulting
              <ol style={{ marginLeft: "1rem" }}>
                <li>National Quality Improvement Plan</li>
              </ol>
            </li>
            <li style={{ margin: "1rem 0" }}>Executive Training</li>
          </ul>
        </div>
      </div>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: 1.5rem;

  .banner2__container {
    width: 100%;
    height: 500px;
    position: relative;

    z-index: -1;
    ul {
      margin-left: 1.5rem;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10000;
      color: white;
      flex-direction: column;
    }
  }
`;
export default Banner3;
