import React from "react";
import styled from "styled-components";
import { BsCalendar3 } from "react-icons/bs";
import { Link } from "react-router-dom";
const CourseCard1 = ({
  image,
  card__heading,
  card__price,
  duration,
  description,
  link,
  provinces,
}) => {
  return (
    <CourseCard1Container>
      <div className="card__image">
        <img src={image} alt="" />
      </div>

      <div className="card__title">
        <div className="heading">
          <p className="card__heading">{card__heading}</p>
        </div>
        <div className="price">
          <p className="card__price">{card__price}</p>
        </div>
      </div>

      <div className="card__row">
        <BsCalendar3 color="grey" className="card__calendar__icon" />
        <p>{duration}</p>
      </div>

      <p>{description}</p>
      <div className="provinces">
        <div className="row">{provinces}</div>
      </div>

      <div className="card__apply__button">
        <Link className="link" to={link}>
          Apply Now
        </Link>
      </div>
    </CourseCard1Container>
  );
};

const CourseCard1Container = styled.div`
  width: 350px;
  height: 550px;
  margin: 2rem;
  position: relative;
  @media (max-width: 870px) {
    width: 100%;
    height: 600px;
  }

  @media (max-width: 580px) {
    width: 100%;
    height: 700px;
  }
  .card__image {
    width: 100%;
    height: 150px;
    @media (max-width: 870px) {
      height: 300px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    .heading {
      width: 70%;
      .card__heading {
        font-weight: bold;
      }
    }

    .price {
      width: 30%;
      .card__price {
        color: red;
        font-weight: bolder;
      }
    }
  }

  .card__row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .card__calendar__icon {
      margin-right: 1rem;
    }
  }

  .card__apply__button {
    width: 100%;
    height: 50px;
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    position: absolute;
    left: 0;
    bottom: 0;

    .link {
      text-decoration: none;
      color: white;
    }
  }

  .provinces {
    width: 100%;
    color: red;
    margin-top: 1rem;
  }
`;
export default CourseCard1;
