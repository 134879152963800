import React from "react";
import BannerComponent from "../../components/BannerComponent";
import Navbar from "../../components/Navbar";
import banner from "../../images/contact__us/contact__us__banner.jpg";
import styled from "styled-components";
import Footer from "../../components/Footer";
import { AiOutlineMail, AiTwotonePhone } from "react-icons/ai";
import Rubin from "../../images/rubin.jpg";
const ContactUs = () => {
  return (
    <>
      <Navbar />
      <BannerComponent banner={banner} heading="Contact Us" />
      <TransportContainer>
        <div className="container">
          <h1 style={{ marginBottom: "5rem" }}>
            Here's How to Get In Touch With The Leadership Team
          </h1>

          <div className="ceo__container">
            <div className="image__container">
              <img src={Rubin} alt="" srcset="" />
            </div>
            <div className="right">
              <h1>
                <span style={{ color: "black" }}>Rubin</span> Pillay
              </h1>

              <div className="occupation">
                <h3>CEO</h3>
              </div>
              <div className="row">
                <div className="icon__container">
                  <AiOutlineMail className="icon" />
                </div>
                <p className="email">rpillay@uab.edu</p>
              </div>
              <div className="row">
                <div className="icon__container">
                  <AiTwotonePhone className="icon" />
                </div>
                <p className="email">+27 82 570 5603</p>
              </div>
            </div>
          </div>
          <div className="details__container">
            <div className="left">
              <div className="content">
                <h1>
                  {" "}
                  <span style={{ color: "black" }}> Sai</span> Pillay
                </h1>
                <div className="title">
                  <h3>Operations Manager </h3>
                  <span style={{ fontSize: "15px" }}>(Hibberdene )</span>
                </div>
                <div className="row">
                  <div className="icon__container">
                    <AiOutlineMail className="icon" />
                  </div>
                  <p className="email">saipillay@aihl.co.za</p>
                </div>
                <div className="row">
                  <div className="icon__container">
                    <AiTwotonePhone className="icon" />
                  </div>
                  <p className="email">+27 82 570 5603</p>
                </div>
              </div>
            </div>
            <div className="left">
              <div className="content">
                <h1>
                  {" "}
                  <span style={{ color: "black" }}> Pamela</span> Mkhasibe
                </h1>
                <div className="title">
                  <h3>Programme Manager</h3>
                </div>
                <div className="row">
                  <div className="icon__container">
                    <AiOutlineMail className="icon" />
                  </div>
                  <p className="email">pammkhasibe@aihl.co.za</p>
                </div>
                <div className="row">
                  <div className="icon__container">
                    <AiTwotonePhone className="icon" />
                  </div>
                  <p className="email">+27 79 587 1684</p>
                </div>
              </div>
            </div>
            <div className="left">
              <div className="content">
                <h1>
                  {" "}
                  <span style={{ color: "black" }}> Stha</span> Gumede
                </h1>
                <div className="title">
                  <h3>Operations Manager</h3>
                  <span style={{ fontSize: "15px" }}>(Shelly Beach )</span>
                </div>
                <div className="row">
                  <div className="icon__container">
                    <AiOutlineMail className="icon" />
                  </div>
                  <p className="email">sthagumede@aihl.co.za</p>
                </div>
                <div className="row">
                  <div className="icon__container">
                    <AiTwotonePhone className="icon" />
                  </div>
                  <p className="email">+27 72 097 2828</p>
                </div>
              </div>
            </div>
            <div className="left">
              <div className="content">
                <h1>
                  {" "}
                  <span style={{ color: "black" }}> Lorraine</span> Lakay{" "}
                </h1>
                <div className="title">
                  <h3>Repairs and Maintenance</h3>
                </div>
                <div className="row">
                  <div className="icon__container">
                    <AiOutlineMail className="icon" />
                  </div>
                  <p className="email">Lorraine@aihl.co.za</p>
                </div>
                <div className="row">
                  <div className="icon__container">
                    <AiTwotonePhone className="icon" />
                  </div>
                  <p className="email">+27 79 842 1486</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransportContainer>
      <Footer />
    </>
  );
};

const TransportContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1.5rem;

  h1 {
    color: red;
    margin-bottom: 0.5rem;
  }

  .container {
    max-width: 1600px;
    margin: auto;
    padding: 1.5rem;

    .ceo__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4rem;

      @media (max-width: 680px) {
        flex-direction: column;
      }

      .right {
        margin-left: 4rem;
        .occupation {
          width: fit-content;
          border-bottom: 3px solid red;
        }

        .row {
          display: flex;
          align-items: center;
          margin: 12px 0;
          .icon__container {
            background-color: red;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            border-radius: 50vw;
            .icon {
              font-size: 1.5rem;
              color: white;
            }
          }

          .email {
            margin-left: 10px;
          }
        }
      }
      .image__container {
        width: 300px;
        height: 300px;
        border-radius: 50vw;
        border: 5px solid red;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50vw;
          object-fit: cover;
          background-position: center;
        }
      }
    }
    .details__container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .left {
        width: 300px;

        margin: 1rem 2rem;
        .content {
          .title {
            width: fit-content;
            border-bottom: 3px solid red;
            margin-bottom: 12px;
          }
          .row {
            display: flex;
            align-items: center;
            margin: 12px 0;
            .icon__container {
              background-color: red;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              border-radius: 50vw;
              .icon {
                font-size: 1.5rem;
                color: white;
              }
            }

            .email {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
`;

export default ContactUs;
