import React from "react";
import styled from "styled-components";
import logo from "../images/icon.png";
const Footer = () => {
  return (
    <FooterContainer>
      <div className="footer__content__container">
        <div className="left">
          <img src={logo} alt="" className="icon" />
        </div>

        <div className="right">
          <div className="title">
            <h1>Contact Us</h1>
            <div className="underline"></div>
          </div>
          <p>Email: programmes@aihl.co.za</p>
          <p>Cell: 079 587 1684</p>
        </div>
      </div>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #f2f2f2;
  .footer__content__container {
    max-width: 1600px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      .icon {
        width: 100px;
        height: 80px;
        object-fit: contain;
      }
    }

    .right {
      .title {
        width: fit-content;
        height: fit-content;
        h1 {
          font-weight: 300;
        }

        .underline {
          width: 65%;
          height: 3px;
          background-color: red;
        }
      }
      p {
        font-weight: bold;
        margin: 0.4rem;
      }
    }

    @media (max-width: 600px) {
      .left {
        display: none;
      }
    }
  }
`;
export default Footer;
