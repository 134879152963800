import React from "react";
import styled from "styled-components";
import Banner from "../../../components/courses__page/hospital__management__hospital__board__and__hospital__ceo__role__in__healthcare__quality__and__patient__safety/Banner";
import Navbar from "../../../components/Navbar";
import Title from "../../../components/Title";
import health__promotion__officer from "../../../images/health__promotion__officer.jpg";
import Information from "../../../components/courses__page/Information";
import Map from "../../../components/courses__page/Map";

import CourseContent from "../../../components/courses__page/hospital__management__hospital__board__and__hospital__ceo__role__in__healthcare__quality__and__patient__safety/CourseContent";
const HospitalManagementHospitalBoardAndHospitalCeoRoleInHealthcareQualityAndPatientSafety =
  () => {
    const coordinates = {
      lat: -30.5754213,
      lng: 30.57521,
    };
    return (
      <Container>
        <Navbar />
        <Banner />
        <div className="container">
          <div className="content__container">
            <div className="title" style={{ marginTop: "1rem" }}>
              <Title
                title={
                  "Hospital Management – Hospital Board and Hospital CEO Role in Healthcare Quality and Patient Safety"
                }
              />
            </div>

            <div className="row__content">
              <div className="left">
                <div className="image">
                  <img src={health__promotion__officer} alt="" />
                </div>

                <CourseContent />
              </div>
              <div className="right">
                <Information
                  duration="12 Months"
                  institution="The African Institute for Health Leadership"
                  venue="Shelly Beach"
                  price="R4 200.00"
                />
                <Map coordinates={coordinates} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  };

const Container = styled.div`
  width: 100%;

  .container {
    width: 100%;

    .content__container {
      max-width: 1600px;
      margin: auto;
      padding: 1rem;

      .row__content {
        width: 100%;
        display: flex;

        .left {
          width: 70%;
          margin-right: 1rem;
          @media (max-width: 1000px) {
            width: 100%;
          }

          .image {
            width: 100%;
            height: 500px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }

        .right {
          width: 30%;
          @media (max-width: 1000px) {
            width: 100%;
          }
        }

        @media (max-width: 1000px) {
          flex-direction: column;
        }
      }
    }
  }
`;
export default HospitalManagementHospitalBoardAndHospitalCeoRoleInHealthcareQualityAndPatientSafety;
