import React from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlinePriceCheck } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { FaLocationArrow } from "react-icons/fa";
import styled from "styled-components";
const Information = ({ duration, institution, venue, price }) => {
  return (
    <Container>
      <div className="information">
        <div className="title">
          <h3>Course Information</h3>
        </div>
        <div className="row">
          <div className="heading">
            <IoMdInformationCircleOutline className="icon" />

            <p>Duration</p>
          </div>
          <div className="content">
            <p>{duration}</p>
          </div>
        </div>
        <div className="row">
          <div className="heading">
            <FaLocationArrow className="icon" />
            <p>Institution</p>
          </div>
          <div className="content">
            <p>{institution}</p>
          </div>
        </div>
        <div className="row">
          <div className="heading">
            <GoLocation className="icon" />

            <p>Venue</p>
          </div>
          <div className="content">
            <p>{venue}</p>
          </div>
        </div>
        <div className="row">
          <div className="heading">
            <MdOutlinePriceCheck className="icon" />
            <p>Price</p>
          </div>
          <div className="content">
            <p>{price}</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  .information {
    width: 100%;

    .row {
      display: flex;
      align-items: center;
      height: fit-content;
      background-color: yellow;

      .heading,
      .content {
        width: 50%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        text-align: center;
        background-color: red;
        color: white;
        border: 1px solid white;
      }

      .heading {
        background-color: #c21d11;
        .icon {
          margin-right: 1rem;
        }
      }
    }
  }
`;
export default Information;
