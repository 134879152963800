import React, { useState } from "react";
import styled from "styled-components";
import logo from "../images/logo.png";
import logo__mobile from "../images/icon.png";
import { CgMenu } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
const Navbar = () => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [facilitiesBtn, setFacilitiesBtn] = useState(false);
  const [facilitiesBtnMobile, setFacilitiesBtnMobile] = useState(false);
  function toggle() {
    setIsMenuClicked(!isMenuClicked);
  }
  return (
    <NavbarContainer>
      {/* {covidBtn === false && isMenuClicked === false && (
        <div className="covid__warning">
          <div className="covid__warning__container">
            <p>
              <span>Stay informed!</span>Visit the SA Department of Health's
              website for COVID-19 updates:{" "}
              <a href="https://www.sacoronavirus.co.za">
                www.sacoronavirus.co.za
              </a>
            </p>
          </div>

          <div className="close">
            <AiOutlineClose
              className="close__btn"
              onClick={() => setCovidBtn(!covidBtn)}
            />
          </div>
        </div>
      )} */}
      <div className="navbar__content">
        <div className="left">
          <div className="left__logo">
            <Link to="/">
              <img src={logo__mobile} alt="" className="navbar__logo" />
            </Link>
            <div className="text">
              <h1>The African Institute for</h1>
              <h2>Health Leadership</h2>
            </div>
          </div>

          <Link to="/">
            <img src={logo__mobile} alt="" className="navbar__logo__mobile" />
          </Link>
        </div>

        <div className="right">
          <ul>
            <Link to="/about_us" className="link">
              About Us
            </Link>
            <Link to="/contact" className="link">
              Contact
            </Link>
            <Link to="/executive_training" className="link">
              Executive Training
            </Link>
            <Link to="/consulting" className="link">
              Consulting
            </Link>
            <Link to="/skills_development" className="link">
              Skills Development
            </Link>
            <p
              className="link facilities"
              onClick={() => setFacilitiesBtn(!facilitiesBtn)}
            >
              Facilities
            </p>
          </ul>

          {facilitiesBtn && (
            <div className="facilities__container">
              <Link to="/accommodation" className="link">
                Accommodation
              </Link>
              <Link to="/catering" className="link">
                Catering
              </Link>
              <Link to="/security" className="link">
                Security
              </Link>
              <Link to="/transportation" className="link">
                Transportation
              </Link>
              <Link to="/classrooms" className="link">
                Classrooms
              </Link>
              <Link to="/courses" className="link">
                Courses
              </Link>
            </div>
          )}
        </div>

        <div
          className="menu__icon"
          onClick={() => setIsMenuClicked(!isMenuClicked)}
        >
          {isMenuClicked === false ? <CgMenu /> : <AiOutlineClose />}
        </div>
      </div>

      <div
        className={isMenuClicked ? "side-menu open" : "side-menu"}
        onClick={() => toggle()}
      >
        <div className="side-container">
          <div className="content">
            <Link to="/about_us" className="link">
              About Us
            </Link>
            <Link to="/contact" className="link">
              Contact
            </Link>
            <Link to="/executive_training" className="link">
              Executive Training
            </Link>
            <Link to="/consulting" className="link">
              Consulting
            </Link>
            <Link to="/skills_development" className="link">
              Skills Development
            </Link>
            <p
              className="link facilities"
              onClick={() => setFacilitiesBtnMobile(!facilitiesBtnMobile)}
            >
              Facilities
            </p>

            <ul style={{ marginLeft: "2rem" }}>
              <li>
                <Link to="/accommodation" className="link mobile">
                  Accommodation
                </Link>
              </li>
              <li>
                <Link to="/catering" className="link mobile">
                  Catering
                </Link>
              </li>
              <li>
                <Link to="/security" className="link mobile">
                  Security
                </Link>
              </li>
              <li>
                <Link to="/transportation" className="link mobile">
                  Transportation
                </Link>
              </li>
              <li>
                <Link to="/classrooms" className="link mobile">
                  Classrooms
                </Link>
              </li>
              <li>
                <Link to="/courses" className="link mobile">
                  Courses
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
};

const NavbarContainer = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 9999;

  .link {
    list-style: none;
    font-size: 1rem;
    margin: 0.5rem 0;
    text-decoration: none;
    color: black;
    padding: 10px;
    border-radius: 10px;

    &:hover {
      transition: 0.1s ease-in-out;
      background-color: #f03c3c;
      color: white;
    }
  }

  .link.facilities {
    cursor: pointer;
  }

  .link.mobile {
    font-size: 15px;
    margin: 0.4rem 0;
  }

  .covid__warning {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: white;
    position: relative;
    padding: 12px;

    .covid__warning__container {
      span {
        font-weight: bold;
      }

      a {
        color: white;
      }
    }
    .close {
      position: absolute;
      right: 10px;

      .close__btn {
        font-size: 2rem;
        cursor: pointer;
      }
    }
  }
  .side-menu {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: red;
    z-index: 10000000;
    right: -100%;
    top: 80px;
    transition: 0.2s ease-in-out;

    .side-container {
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-y: scroll;
      z-index: 10000000;
      .content {
        display: flex;
        flex-direction: column;

        ul {
          margin-right: 2rem;

          li {
            margin: 1rem 0;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .side-menu.open {
    right: 0;
    z-index: 10000000;
  }

  .navbar__content {
    .menu__icon {
      display: none;
      font-size: 2.4rem;
      cursor: pointer;
    }
    padding: 12px;
    max-width: 1600px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      .left__logo {
        display: flex;
        align-items: center;
        .navbar__logo {
          width: auto;
          height: 70px;
          object-fit: contain;
          margin-right: 1rem;
        }
        .text {
          text-align: center;
          h1 {
            font-size: 0.8rem;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 0;
            @media (max-width: 500px) {
              font-size: 11px;
            }
          }
          h2 {
            color: red;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 1.4rem;

            @media (max-width: 500px) {
              font-size: 13px;
            }
          }
        }
      }
      .navbar__logo__mobile {
        width: 200px;
        height: 70px;
        object-fit: contain;
        display: none;
      }

      @media (max-width: 400px) {
        .navbar__logo__mobile {
          width: 100px;
          height: 70px;
          object-fit: contain;
          display: block;
        }
        .navbar__logo {
          display: none;
        }

        .text {
          display: none;
        }
      }
    }

    .right {
      position: relative;
      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        li {
          list-style: none;
          margin: 0px 7px;
          padding: 10px;
          font-weight: bold;
          &:hover {
            background-color: #c7c7c7;
            border-radius: 12px;
          }
        }
      }

      .facilities__container {
        background-color: #f5f5f6;
        height: 400px;
        position: absolute;
        right: -125px;
        display: flex;
        flex-direction: column;
        width: 50%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;

        @media (max-width: 1880px) {
          right: 0w;
        }
      }

      @media (max-width: 1220px) {
        display: none;
      }
    }

    @media (max-width: 1220px) {
      .menu__icon {
        display: block;
      }
    }
  }
`;
export default Navbar;
