import React from "react";
import BannerComponent from "../../components/BannerComponent";
import Navbar from "../../components/Navbar";
import banner from "../../images/about__us/about__us__banner.jpg";
import styled from "styled-components";
import Footer from "../../components/Footer";
import classroom from "../../images/classroom_1.jpg";
const AboutUs = () => {
  return (
    <>
      <Navbar />
      <BannerComponent banner={banner} heading="About Us" />
      <TransportContainer>
        <div className="container">
          <h1>Some Information About Us</h1>

          <p className="content">
            The African Institute for Health Leadership aims to provide thought
            leadership and provide skills development through a number of our
            initiatives and programming. The Institute is based in Gauteng and
            on the Natal South Coast with locations in Hibberdene & Shelly
            Beach. Our capacity development initiatives target executives and
            senior management from health, social development and e government.
            The institute also focuses on empowering individuals from
            disadvantaged communities by offering turn key educational and
            training services including transportations, accommodation and
            programmings. At the Institute we offer services to cater to
            students to ensure that they are comfortable whilst completing their
            studies. We offer Accommodation, In-House Catering as well as
            Transportation to and from the students homes & the Institute.
          </p>
        </div>
      </TransportContainer>

      <Footer />
    </>
  );
};

const TransportContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1.5rem;

  h1 {
    color: red;
    margin-bottom: 1rem;
  }

  .container {
    max-width: 1600px;
    margin: auto;
    padding: 1.5rem;
  }
`;

export default AboutUs;
