import React from "react";
import styled from "styled-components";
import banner2 from "../../images/banner2.jpg";
import Title from "../Title";
const Banner2 = () => {
  return (
    <BannerContainer>
      <div className="banner2__container">
        <img src={banner2} alt="" />
        <div className="content">
          <Title title={"Our Purpose"} />
          <p>
            Health and Healthcare transformation through innovation and high
            reliability
          </p>
        </div>
      </div>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  width: 100%;
  height: fit-content;

  .banner2__container {
    width: 100%;
    height: 500px;
    background-color: red;
    position: relative;
    z-index: -1;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10000;
      color: white;
      flex-direction: column;
    }
  }
`;
export default Banner2;
