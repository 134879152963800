import React from "react";
import styled from "styled-components";
const Banner = () => {
  return (
    <BannerContainer>
      <img src={require("../../../images/ecd__banner.jpg")} alt="" />
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  width: 100%;
  height: 350px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
export default Banner;
