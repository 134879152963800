import React from "react";
import styled from "styled-components";
import accordionData from "../../data/banner3Content.json";
const Banner3Accordion = () => {
  return (
    <AccodrionContainer>
      <div className="accordion__content__container">
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
        <p>ss</p>
      </div>
    </AccodrionContainer>
  );
  <p>ss</p>;
};

const AccodrionContainer = styled.div`
  width: 100%;
  height: 500px;
  background-color: red;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  .accordion__content__container {
    width: 50%;
    height: 300px;
    background-color: yellow;
    overflow-y: scroll;
    z-index: 999999999999999999999999999999999999;
  }
`;
export default Banner3Accordion;
