import React from "react";
import BannerComponent from "../../components/BannerComponent";
import Navbar from "../../components/Navbar";
import banner from "../../images/security/security_4.jpg";
import styled from "styled-components";
import Footer from "../../components/Footer";
import SecurityContent from "../../components/security__page/SecurityContent";
const SecurityPage = () => {
  return (
    <>
      <Navbar />
      <BannerComponent banner={banner} heading="Security" />
      <SecurityPageContainer>
        <SecurityContent />
      </SecurityPageContainer>
      <Footer />
    </>
  );
};

const SecurityPageContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1.5rem;
`;

export default SecurityPage;
