import React from "react";
import BannerComponent from "../../components/BannerComponent";
import Navbar from "../../components/Navbar";
import banner from "../../images/classroom/classroom_3.jpg";
import styled from "styled-components";
import Footer from "../../components/Footer";
const Consulting = () => {
  return (
    <>
      <Navbar />
      <BannerComponent banner={banner} heading="Consulting" />
      <TransportContainer>
        <div className="container">
          <h1>Consulting </h1>
        </div>
      </TransportContainer>
      <Footer />
    </>
  );
};

const TransportContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1.5rem;

  h1 {
    color: red;
    margin-bottom: 1rem;
  }

  .container {
    max-width: 1600px;
    margin: auto;
    padding: 1.5rem;
  }
`;

export default Consulting;
