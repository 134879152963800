import React from "react";
import BannerComponent from "../../components/BannerComponent";
import Navbar from "../../components/Navbar";
import banner from "../../images/transportation/transport_3.jpg";
import styled from "styled-components";
import Footer from "../../components/Footer";
import TransportContent from "../../components/transport__page/TransportContent";
const Transport = () => {
  return (
    <>
      <Navbar />
      <BannerComponent banner={banner} heading="Transport" />
      <TransportContainer>
        <TransportContent />
      </TransportContainer>
      <Footer />
    </>
  );
};

const TransportContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 1.5rem;
`;

export default Transport;
