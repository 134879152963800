import React, { useState } from "react";
import styled from "styled-components";
import { BsChevronCompactRight } from "react-icons/bs";
const CourseContent = () => {
  const [recepients, setRecepients] = useState([]);
  return (
    <Container>
      <h1 className="module">FETC: ECD Level 4 (SAQA 56871)</h1>
      <br />
      <h2 className="heading">Purpose of the NQF 4 Qualification</h2>

      <p>
        To provide basic knowledge and skills in early childhood development
        (birth to five years) in order to facilitate all-round development of
        young children in a manner that is sensitive to culture and individual
        needs (including special needs) in a variety of contexts, including
        community-based services, ECD centres, at home and in institutions.
        Students who graduate with this qualification may study further towards
        the Diploma in ECD (NQF Level 5) SAQA ID 23118.
      </p>
      <br />
      <h2 className="heading">Target group</h2>

      <ul>
        <li>School leavers who have an interest in ECD</li>
        <li>
          ECD practitioners without formal ECD qualifications or recognition
        </li>
        <li>Au pairs and day care mothers</li>
      </ul>
      <ul>
        <li>
          ID 64749 – National Certificate: Community Health Work NQF Level 2
        </li>

        <li>
          ID 64749 – National Certificate: Community Health Work NQF Level 3
        </li>
      </ul>
      <br />
      <h2 className="heading">This entry level qualification is for:</h2>

      <p>
        This qualification is suitable to provide basic knowledge and Skills in
        Early Childhood Development for children from birth to 5 years
      </p>
      <br />
      <h2 className="heading">Admission Requirements</h2>

      <p>
        For all qualifications, proficiency in English is essential as English
        is the language of instruction. You must be able to read, comprehend
        study material and write in English.
      </p>
      <ul>
        <li>Minimum of Grade 11 (Standard 9)</li>
      </ul>
      <p>
        NOTE: Applicants with a Matric or a National Senior Certificate (i.e.
        Grade 12) and a pass in the following subjects: Math Literacy or
        Mathematics, English Communication and Second South African language
        will be exempt from the Fundamental component (56 credits)
      </p>
      <br />
      <h2 className="heading">Mode of Delivery</h2>

      <p>
        Facilitated contact sessions at a training venue and practical
        "hands-on" (teaching experience) at a registered ECD Centre.
      </p>
      <br />
      <h2 className="heading">Curriculum Content and Structure</h2>

      <p>
        The qualification is made up of three components: Fundamental, Core and
        Elective. Each component is divided into Learning Units. The total
        number of credits gained by completing this course is 145.
      </p>
      <br />
      <h2 className="heading">FUNDAMENTAL COMPONENT (56 Credits)</h2>
      <h3>Learning Unit 1:</h3>
      <p>English Communication</p>
      <ul>
        <li>
          Engage in sustained oral/signed communication and evaluate
          spoken/signed texts
        </li>
        <li>
          Interpret and use information from texts in occupational learning
          programmes
        </li>
        <li>Write/present/sign for a wide range of contexts</li>
        <li>
          Read/view, analyse and respond to a variety of texts Learning Unit 1
          Second Language
        </li>
        <li>Write/present/sign texts for a range of communicative contexts</li>
        <li>
          Accommodate audience and context needs in oral/signed communication
        </li>
        <li>Interpret and use information from texts</li>
        <li>
          Use language and communication in occupational learning programmes
        </li>
      </ul>
      <br />
      <h3>Learning Unit 2: Maths. Literacy</h3>
      <ul>
        <li>
          Use mathematics to investigate and monitor the financial aspects of
          personal, business, national and international issues
        </li>
        <li>
          Apply knowledge of statistics and probability to critically
          interrogate and effectively communicate findings on life related
          problems
        </li>
        <li>
          Represent analyse and calculate shape and motion in 2-and
          3-dimensional space in different contexts
        </li>
      </ul>
      <br />
      <h2 className="heading">CORE COMPONENT (64 Credits)</h2>
      <h3>
        Learning Unit 3: Provide care and support for babies, toddlers, and
        young children
      </h3>
      <ul>
        <li>
          Work with families and communities to support Early Childhood
          Development
        </li>
        <li>Provide care for babies, toddlers, and young children </li>
      </ul>
      <br />
      <h3>Learning Unit 4: Plan and prepare for early childhood development</h3>
      <ul>
        <li>
          Demonstrate knowledge and understanding of the development of babies,
          toddlers, and young children
        </li>
        <li>Prepare Early Childhood Development programmes with support</li>
        <li>
          Prepare resources and set up the environment to support the
          development of babies, toddlers, and young children
        </li>
      </ul>
      <br />
      <h3>
        Learning Unit 5: Facilitate and monitor the holistic development of
        children
      </h3>
      <ul>
        <li>
          Facilitate the holistic development of babies, toddlers, and young
          children
        </li>
        <li>
          Design activities to support the development of babies, toddlers, and
          young children
        </li>
        <li>Observe and report on child development</li>
      </ul>
      <br />
      <h2 className="heading">ELECTIVE COMPONENT (26 Credits)</h2>
      <h3>Learning Unit 6: Managing and evaluating an ECD Centre</h3>
      <ul>
        <li>Manage the ECD Service</li>
        <li>Evaluate the ECD Service</li>
        <li>Conduct a Structured Meeting</li>
        <li>Motivate and build a Team-Teaching</li>
        <li>Support Children and adults living with HIV & AIDS</li>
        <li>
          Develop learning programmes to enhance participation of learners with
          special needs
        </li>
      </ul>
      <br />
      <h2 className="heading">Practice</h2>

      <p>
        Some of your practical assignments will require you to spend time in an
        ECD centre. We call this "teaching practice" and is a vital component of
        your course. Teaching practice gives you the opportunity to observe
        teaching in a real classroom setting. You will be able to 'put into
        practice' what you have learnt e.g., lesson planning, making creative
        resources, etc. To carry out your teaching practice, you need to find a
        registered ECD centre near you. The principal will submit a written
        report on your teaching practice and performance.
      </p>
      <br />
      <h2 className="heading">Assessment</h2>

      <p>
        Formative assessment takes place throughout the course. You will be
        required to submit written work as well as evidence of practical and
        workplace activities (e.g., hand making a puppet, observing an ECD
        teacher in a classroom, etc.). These assessments will be submitted as a
        Portfolio of Evidence (POE) and will contribute towards your Final
        Summative Assessment (FSA).
      </p>
      <br />
      <h2 className="heading">Duration of the course</h2>

      <p>The time taken to complete the course is 12 months.</p>
      <br />
      <h1>Diploma: ECD Level 5 (SAQA 23118)</h1>
      <h2 className="heading">Purpose of the NQF 5 Qualification</h2>

      <p>
        To develop essential ECD knowledge and skills that practitioners need to
        implement in order to grow a successful professional ECD practice by
        introducing the different theoretical teaching and learning philosophies
        and practices, relevant government policies (e.g., NCF, NELDS, CAPS) and
        regulations that affect ECD on a national and provincial level. Upon
        successful graduation, you will be able to access the National Diploma
        in Grade R Teaching (NQF Level 6) and the B. Ed (ECCE) with possible
        Credit Accumulation and Transfer (CAT).
      </p>
      <br />
      <h2 className="heading">Target group</h2>

      <ul>
        <li>FETC: ECD Level 4 graduates</li>
        <li>Matriculants</li>
        <li>Assistant ECD teachers and practitioners</li>
        <li>Entrepreneurs interested in starting an ECD Centre.</li>
      </ul>
      <br />
      <h2 className="heading">This entry level qualification is for:</h2>

      <p>
        This qualification is suitable to teach babies, toddlers, and young
        children. Age 0 to grade R.
      </p>
      <br />
      <h2 className="heading">Admission Requirements</h2>

      <p>
        For all qualifications, proficiency in English is essential as English
        is the language of instruction. You must be able to read, comprehend
        study material and write in English.
      </p>
      <ul>
        <li>FETC: ECD (Level 4) qualification; or</li>
        <li>Matric or National Senior Certificate (i.e., Grade 12); or</li>
        <li>
          Minimum of 2 years' ECD experience and a letter from the principal of
          the ECD centre supporting the application.
        </li>
        <p>
          Note: Matriculants who have a minimum of 2 years' experience will be
          required to write an entrance test which focuses on important ECD
          concepts and attend an interview
        </p>
      </ul>
      <br />
      <h2 className="heading">Mode of Delivery</h2>

      <p>
        Facilitated contact sessions at a training venue and practical
        "hands-on" (teaching experience) at a grade R class within a school.
      </p>
      <br />
      <h2 className="heading">Curriculum Content and Structure</h2>

      <p>
        The qualification is made up of three components: Fundamental, Core and
        Elective. Each component is divided into Learning Units. The total
        number of credits gained by completing this course is 240.
      </p>
      <br />
      <h2 className="heading">FUNDAMENTAL COMPONENT (35 Credits)</h2>
      <h3>Learning Unit 1: Workplace Communication</h3>
      <ul>
        <li>Develop and apply academic literacy</li>
        <li>Apply workplace communication skills</li>
      </ul>
      <br />
      <h2 className="heading">CORE COMPONENT (72 Credits)</h2>
      <h3>
        Learning Unit 2: Mediate Active Learning and Manage ECD programmes
      </h3>
      <ul>
        <li>Mediate active learning in ECD programmes</li>
        <li>Promote healthy development in Early Childhood programmes</li>
        <li>Develop and manage the ECD learning programme</li>
      </ul>
      <br />
      <h2 className="heading">ELECTIVE COMPONENT (136 Credits)</h2>
      <h3>Learning Unit 3: Facilitating Programme in Grade R Year</h3>
      <ul>
        <li>
          Facilitate a Life Skills Learning Programme in the Reception Year
        </li>
        <li>Facilitate a Literacy Learning Programme in the Reception Year</li>
        <li>Facilitate a Numeracy Learning Programme in the Reception Year</li>
        <li>Managing the learning programme</li>
        <li>Manage diversity in ECD Setting</li>
        <li>
          Facilitate an inclusive Educational Environment in an ECD Setting
        </li>
        <li>Conduct outcomes-based assessment</li>
        <li>
          Identify and respond to learners with Special Needs and Barriers to
          learning.
        </li>
        <li>Manage a medium scale ECD Service</li>
      </ul>
      <br />
      <h2 className="heading">Teaching Practice</h2>

      <p>
        Some of your practical assignments will require you to spend time in an
        ECD centre. We call this "teaching practice" and is a vital component of
        y our course. Teaching practice gives you the opportunity to observe
        teaching in a real classroom setting. You will be able to 'put into
        practice' what you have learnt e.g. lesson planning, making creative
        resources, etc. To carry out your teaching practice, you need to find
        placement at a Grade R class within a school near you. The
        principal/mentor will submit a written report on your teaching practice
        and performance.
      </p>
      <br />
      <h2 className="heading">Assessment</h2>

      <p>
        Formative assessment takes place throughout the course. You will be
        required to submit written work as well as evidence of practical and
        workplace activities (e.g. writing a lesson plan, presenting a lesson to
        a class of children, observing and recording a child's progress, etc.).
        These assessments will be submitted as a Portfolio of Evidence (POE) and
        will contribute towards your Final Summative Assessment (FSA).
      </p>
      <br />
      <h2 className="heading">Duration of the course</h2>

      <p>The time taken to complete the course is 24 months.</p>
    </Container>
  );
};

const Container = styled.div`
  ul,
  ol {
    margin-left: 2rem;
  }

  h2 {
    color: red;
  }

  .heading {
    font-size: 1.5rem;
    font-weight: 300;
    text-decoration: underline;
  }
  .module {
    font-weight: 300;
  }
  .dropdown__container {
    width: 100%;

    .dropdown__1 {
      cursor: pointer;
      transition: 0.4s ease-in-out;
      padding: 2rem;
      h1 {
        font-size: 1rem;
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;
export default CourseContent;
