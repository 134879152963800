import "./App.css";
import LandingPage from "./pages/landing__page/LandingPage";
import { Routes, Route } from "react-router-dom";
import Accommodation from "./pages/accommodation__page/Accommodation";
import Courses from "./pages/courses__page/Courses";
import HealthPromotionOfficer from "./pages/courses__page/health__promotion__officer/HealthPromotionOfficer";
import ChildAndHealthCareWorker from "./pages/courses__page/child__and__youth__care__worker/ChildAndYouthCareWorker";
import Catering from "./pages/catering__page/Catering";
import SecurityPage from "./pages/security__page/SecurityPage";
import Transportation from "./pages/transportation__page/Transportation";
import Classroom from "./pages/classrooms__page/Classroom";
import EarlyChildhoodDevelopment from "./pages/courses__page/early__childhood__development/EarlyChildhoodDevelopment";
import ExecutiveTraining from "./pages/executive__training__page/ExecutiveTraining";
import Consulting from "./pages/consulting__page/Consulting";
import SkillsDevelopment from "./pages/skills__development__page/SkillsDevelopment";
import AboutUs from "./pages/about__us__page/AboutUs";
import ContactUs from "./pages/contact__us__page/ContactUs";
import HealthManagementAndLeadershipCpdWorkshop from "./pages/courses__page/health_management_and_leadership_cpd_workshop/HealthManagementAndLeadershipCpdWorkshop";
import HospitalManagement from "./pages/courses__page/hospital__management__hospital__board__and__hospital__ceo__role__in__healthcare__quality__and__patient__safety/HospitalManagement";
import NonCreditBearingSkillsProgramme from "./pages/courses__page/non__credit__bearing__skills__programme/NonCreditBearingSkillsProgramme";
function App() {
  return (
    <Routes>
      <Route path="/" exact element={<LandingPage />} />
      <Route path="accommodation" exact element={<Accommodation />} />
      <Route path="catering" exact element={<Catering />} />
      <Route path="courses" exact element={<Courses />} />
      <Route path="security" exact element={<SecurityPage />} />
      <Route path="transportation" exact element={<Transportation />} />
      <Route path="classrooms" exact element={<Classroom />} />
      <Route path="/about_us" exact element={<AboutUs />} />
      <Route path="/contact" exact element={<ContactUs />} />
      <Route path="/executive_training" exact element={<ExecutiveTraining />} />
      <Route path="/consulting" exact element={<Consulting />} />
      <Route path="/skills_development" exact element={<SkillsDevelopment />} />
      <Route
        path="/courses/health_management_and_leadership_cpd_workshop"
        exact
        element={<HealthManagementAndLeadershipCpdWorkshop />}
      />
      <Route
        path="/courses/hospital__management__hospital__board__and__hospital__ceo__role__in__healthcare__quality__and__patient__safety"
        exact
        element={<HospitalManagement />}
      />
      <Route
        path="/courses/non__credit__bearing__skills__programme"
        exact
        element={<NonCreditBearingSkillsProgramme />}
      />
      <Route
        path="courses/health_promotion_officer"
        exact
        element={<HealthPromotionOfficer />}
      />
      <Route
        path="courses/child_and_youthcare_worker"
        exact
        element={<ChildAndHealthCareWorker />}
      />
      <Route
        path="courses/early_childhood_development"
        exact
        element={<EarlyChildhoodDevelopment />}
      />
    </Routes>
  );
}

export default App;
