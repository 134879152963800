import React from "react";
import styled from "styled-components";
const Title = ({ title }) => {
  return (
    <TitleContainer>
      <h1>{title}</h1>
      <div className="underline"></div>
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  width: fit-content;
  margin-bottom: 12px;
  h1 {
    font-weight: 300;
  }
  .underline {
    width: 100%;
    height: 3px;
    background-color: #ff0000;
    display: flex;
  }
`;
export default Title;
