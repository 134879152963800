import React from "react";
import styled from "styled-components";
const Course2 = () => {
  return (
    <Course1Container>
      <div className="course__1__content__container">
        <h1>QUALIFICATION ID – 60209 Child and Youth Care Worker</h1>

        <h2>Purpose</h2>
        <p>
          This Qualification is an entry-level qualification for those who want
          to enter the field of Child & Youth Care Work (C&YCW) as a potential
          career. It builds on practical experience and community-based
          learning, and equips people for their role as an auxiliary child and
          youth care worker. As such the qualification will also be valuable for
          those who may have been practicing within the field, but without
          formal recognition.
        </p>
        <br />

        <h3>Recipients of this Qualification will be able to:</h3>
        <ul>
          <li>
            Perform clearly defined tasks and activities which contribute to
            holistic care and development of the young person.
          </li>
          <li>
            Develop themselves purposefully, professionally and as a resource
            for C&YCW.
          </li>
          <li>
            Demonstrate knowledge of and work in ways that are consistent with
            the ethics of the field and workplace.
          </li>
          <li>
            Demonstrate understanding of on-line and consultative supervision
            and their role in the provision of care and own personal
            development.
          </li>
          <li>
            Describe and uphold the rights of young people, and promote these in
            their practice.
          </li>
          <li>Solve common problems within familiar contexts </li>
        </ul>

        <h2>Career Path</h2>
        <ul>
          <li>Community Development</li>
          <li>Community Health Work</li>
          <li>Psycology/Counselling</li>
          <li>Youth Development</li>
          <li>Social Work</li>
          <li>Primary Health Care</li>
          <li>Health Studies</li>
        </ul>

        <div className="table__container">
          <div className="table">
            <div className="table__header">
              <div className="table__data module_code ">
                <h2>Module Code</h2>
              </div>
              <div className="table__data module_title">
                <h2>Module Title</h2>
              </div>
              <div className="table__data module_credit">
                <h2>Credits</h2>
              </div>
            </div>

            <div className="table__body">
              <div className="table__row">
                <div className="table__data module_code">
                  <p>FETC901</p>
                </div>
                <div className="table__data module_title">
                  <p>Fundamentals of child and youth care work</p>
                </div>
                <div className="table__data module_credit">
                  <p>27</p>
                </div>
              </div>

              <div className="table__row">
                <div className="table__data module_code">
                  <p>FETC902</p>
                </div>
                <div className="table__data module_title">
                  <p>Developmental theories in child and youth care work</p>
                </div>
                <div className="table__data module_credit">
                  <p>23</p>
                </div>
              </div>

              <div className="table__row">
                <div className="table__data module_code">
                  <p>FETC903</p>
                </div>
                <div className="table__data module_title">
                  <p>Child and youth care work practice</p>
                </div>
                <div className="table__data module_credit">
                  <p>20</p>
                </div>
              </div>

              <div className="table__row">
                <div className="table__data module_code">
                  <p>FETC904</p>
                </div>
                <div className="table__data module_title">
                  <p>Interpersonal skills in child and youth care work</p>
                </div>
                <div className="table__data module_credit">
                  <p>33</p>
                </div>
              </div>

              <div className="table__row">
                <div className="table__data module_code">
                  <p>FETC905</p>
                </div>
                <div className="table__data module_title">
                  <p>Communication for occupational purposes</p>
                </div>
                <div className="table__data module_credit">
                  <p>40</p>
                </div>
              </div>

              <div className="table__row">
                <div className="table__data module_code">
                  <p>FETC906</p>
                </div>
                <div className="table__data module_title">
                  <p>Introduction to mathematics & statistics</p>
                </div>
                <div className="table__data module_credit">
                  <p>16</p>
                </div>
              </div>

              <div className="table__row">
                <div className="table__data module_code">
                  <p>FETC907</p>
                </div>
                <div className="table__data module_title">
                  <p>Wellness in the workplace & community</p>
                </div>
                <div className="table__data module_credit">
                  <p>10</p>
                </div>
              </div>

              <div className="table__row">
                <div className="table__data module_code">
                  <p>FETC908</p>
                </div>
                <div className="table__data module_title">
                  <p>908 Management of community projects</p>
                </div>
                <div className="table__data module_credit">
                  <p>22</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Course1Container>
  );
};
const Course1Container = styled.div`
  width: 100%;
  padding: 1rem;

  .table__container {
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .table {
      width: 800px;
      margin: auto;

      .table__header {
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid lightgrey;

        .table__data {
          width: 33.33%;
          padding: 1.2rem;
        }
        .table__data.module_code {
          width: 20%;
        }
        .table__data.module_title {
          width: 60%;
        }
        .table__data.module_credit {
          width: 20%;
        }
      }

      .table__body {
        width: 100%;

        .table__row {
          width: 100%;
          display: flex;
          align-items: center;

          .table__data {
            width: 33.33%;
            padding: 1.2rem;
            display: flex;
            align-items: center;
          }

          .table__data.module_code {
            width: 20%;
          }
          .table__data.module_title {
            width: 60%;
          }
          .table__data.module_credit {
            width: 20%;
          }
        }
      }
    }
  }

  ul,
  ol {
    margin-left: 2rem;
  }
  .course__1__content__container {
    max-width: 1600px;
    margin: auto;
    -webkit-box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.4);
    padding: 2rem;
    border-radius: 12px;

    h1 {
      font-size: 2rem;
      font-weight: 500;
      color: red;
    }
  }
`;
export default Course2;
